import React from 'react';
import type { FC } from 'react';
import { Radio, RadioProps } from '@mui/material';

type ACRadioProps = RadioProps;

export const ACRadio: FC<ACRadioProps> = props => {
  return (
    <Radio
      size="small"
      sx={{
        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
          color: '#C9CED6',
        },
        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
          color: 'blue',
        },
      }}
      {...props}
    />
  );
};
