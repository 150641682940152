import { useCallback } from 'react';

export function useLocalStorage<T>() {
  return useCallback((key: string, value: T | ((val: T) => T)) => {
    // Prevent build error "window is undefined" but keep working
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
    }

    try {
      // Allow value to be a function, so we have the same API as useState
      const valueToStore =
        value instanceof Function
          ? value(
              window.localStorage.getItem(key)
                ? JSON.parse(window.localStorage.getItem(key) as string)
                : null
            )
          : value;

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  }, []);
}
