import { useState, useEffect } from 'react';

export function useReadLocalStorage<T>(key: string): T | null {
  // Get from local storage then
  // parse stored json or return null
  const readValue = (): T | null => {
    // Prevent build error "window is undefined" but keep working
    if (typeof window === 'undefined') {
      return null;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return null;
    }
  };

  const [storedValue, setStoredValue] = useState(readValue);

  useEffect(() => {
    setStoredValue(readValue());
  }, [key]);

  return storedValue;
}
