import React from 'react';

export interface ACIconProps {
  className?: string;
  imgSrc: string;
  size?: number;
  alt?: string;
  onClick?: () => void;
}

export const ACIcon = (props: ACIconProps) => {
  const { className, size, imgSrc, alt, onClick } = props;

  return <img src={imgSrc} width={size} className={className} alt={alt} onClick={onClick} />;
};
