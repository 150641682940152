export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const checkIsAllValueEmpty = (obj: { [key: string]: string }) => {
  for (const key in obj) {
    if (obj[key] !== '') {
      return false;
    }
  }
  return true;
};

export const formatCurrency = (amount: number | undefined | null) => {
  return `$${amount?.toFixed(2)}`;
};

export const dollarFormat = (num: number | null) => {
  return '$' + num?.toLocaleString('en-US');
};

export const reverseDollarFormat = (dollarString: string) => {
  return Number(dollarString.replace(/[$,]/g, ''));
};

/**
 * Validate email address
 * @param email
 */
export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// Rename the business entity name to the dropdown mapping
export const renameBusinessEntity = (type: string) => {
  switch (type) {
    case 'PrivateCompany':
      return 'Private Company';
    case 'SoleTrader':
      return 'Sole Trader';
    default:
      return type;
  }
};

// Revere tne business entity name to the backend mapping
export const reverseBusinessEntityName = (type: string) => {
  switch (type) {
    case 'Private Company':
      return 'PrivateCompany';
    case 'Sole Trader':
      return 'SoleTrader';
    default:
      return type;
  }
};
