import { useMutation } from '@tanstack/react-query';
import { useAxios } from 'shared/hooks';

import { Quote, QuoteRequestType } from 'shared/models';

export const useUpdateQuote = (id?: string) => {
  const { patch } = useAxios();

  return useMutation<Quote, Error, QuoteRequestType>(
    async (params: QuoteRequestType) =>
      await patch<QuoteRequestType, Quote>(`/api/v2/policyquotes/${id}`, params)
  );
};
