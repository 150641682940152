import React, { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetTaxAuditApp } from 'shared/hooks/api/useGetTaxAuditApp';
import { stageToPathMapping } from 'shared/constants';
import { ACLoading } from 'shared/components';

interface StageValidatorGuardProps {
  children: React.ReactNode;
}

const uuidPattern = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;

const StageValidatorGuard = ({ children }: StageValidatorGuardProps) => {
  const [isInitialLoadingComplete, setIsInitialLoadingComplete] = useState(false);
  const location = useLocation();
  const id = location.pathname.match(uuidPattern)?.join('');
  const navigate = useNavigate();
  const { data: TaaData, isRefetching } = useGetTaxAuditApp(id);

  useLayoutEffect(() => {
    if (!isRefetching && TaaData?.stage && TaaData?.stage !== 'EntityType_Stage' && id) {
      const savedStage = stageToPathMapping[TaaData.stage as keyof typeof stageToPathMapping];

      if (TaaData.stage === 'Primary_Stage' && TaaData?.entityGroup !== 'BusinessGroup') {
        navigate(`/primary/${TaaData?.entityGroup}/${id}`);
      } else {
        navigate(savedStage.replace(':id', id));
      }
    } else {
      setIsInitialLoadingComplete(true);
    }
  }, [TaaData, isRefetching, navigate, id]);

  if (!isInitialLoadingComplete) {
    return <ACLoading />;
  }

  return <>{children}</>;
};

export default StageValidatorGuard;
