import React from 'react';

import { formatCurrency } from 'shared/utils';
import { ACQuoteProps } from 'shared/components/taa/Quote/Quote-desktop';

export const ACQuoteMobile = ({ quote }: ACQuoteProps) => {
  return (
    <div className="bg-black text-white p-6 rounded-lg">
      <div className="mb-10 text-lg font-semibold text-center">YOUR QUOTE</div>

      <div className="text-[44px] leading-10 font-normal text-center">
        <span className="mr-2.5 mb-4">{formatCurrency(quote?.total)}</span>
        <span className="text-2xl block mt-2">Annually</span>
      </div>
    </div>
  );
};

export default ACQuoteMobile;
