import React from 'react';
import { DialogTitle, DialogContent, DialogActions, Dialog } from '@mui/material';

interface IACDialog {
  content: React.ReactNode;
  actionBtn?: React.ReactNode;
  title?: React.ReactNode;
  open: boolean;
  handleClose: () => void;
}

export const ACDialog = ({ content, actionBtn, title, open, handleClose }: IACDialog) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
        {title && <DialogTitle className="flex justify-center items-center">{title}</DialogTitle>}

        <DialogContent sx={{ padding: '30px 40px' }}>{content}</DialogContent>

        <DialogActions sx={{ justifyContent: 'center', marginBottom: '20px' }}>
          {actionBtn}
        </DialogActions>
      </Dialog>
    </div>
  );
};
