import { useQuery } from '@tanstack/react-query';

import { staleTime } from './config/config';
import { useAxios } from 'shared/hooks';
import { TaaResponseType } from 'shared/models';

export const useGetTaxAuditApp = (policyId?: string, enablePolling = false) => {
  const { get } = useAxios();

  return useQuery<TaaResponseType>({
    queryKey: ['tax-audit-app', policyId],
    queryFn: async () => {
      const url = policyId
        ? `/api/v2/policyapplications/${policyId}`
        : '/api/v2/policyapplications';

      return await get<TaaResponseType>(url);
    },
    enabled: !!policyId,
    staleTime,
    refetchInterval: enablePolling ? 500 : undefined,
  });
};
