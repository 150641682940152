import React from 'react';

export interface ACCardProps {
  children: React.ReactNode;
  selected?: boolean;
  onClick?: (cardId: string) => void;
  cardId?: string;
  className?: string;
}

export const ACCard = ({ selected = false, children, onClick, cardId, className }: ACCardProps) => {
  const handleClick = () => {
    if (onClick && cardId) {
      onClick(cardId);
    }
  };

  return (
    <div
      className={`bg-white border-solid ${
        selected ? 'border-[#06D1DF] border-2' : 'border-gray-300 border'
      } hover:shadow-md hover:-translate-y-2.5 duration-200 cursor-pointer ${className}`}
      onClick={handleClick}
    >
      <div className="row min-h-[50px]"></div>

      <div className="row items-center min-h-[50px]">{children}</div>

      <div className="row min-h-[50px]"></div>
    </div>
  );
};
