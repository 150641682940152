import ReactGA from 'react-ga4';

import config from 'shared/assets/config';
import type { PolicyData, Quote, Partner } from 'shared/models';

export interface GA4Config {
  measurementId?: string;
}

export interface EventParams {
  transaction_id: string;
  affiliation?: string;
  value: number;
  currency: string;
  tax?: number;
  shipping?: number;
  items: EventItem[];
}

export interface EventItem {
  item_id: string;
  item_name: string;
  item_brand?: string;
  item_category?: string;
  price: number;
  quantity: number;
}

const warn = (...args: unknown[]) => {
  if (config.environment === 'prod') {
    return;
  }

  console.warn(...args);
};

class GA4 {
  measurementId?: string;

  initialized = false;

  configure(config: GA4Config) {
    if (!config.measurementId) {
      warn('GA4 requires a Measurement ID to be loaded.');
      return;
    }

    this.measurementId = config.measurementId;
  }

  initialize(config: GA4Config) {
    this.configure(config);

    if (this.initialized) {
      warn('GA4 can only be initialized once.');
      return;
    }

    if (!this.measurementId) {
      return;
    }

    ReactGA.initialize(this.measurementId);
    this.initialized = true;
  }

  private buildEcommerce(
    policyData: PolicyData,
    taxAuditQuote: Quote,
    partner: Partner,
    paymentType?: string,
    paymentId?: string
  ): any {
    if (!policyData || !taxAuditQuote) {
      return undefined;
    }
    const ecommerceItem = this.buildEcommerceItem(policyData, taxAuditQuote, partner);
    return {
      currency: ecommerceItem?.currency,
      value: ecommerceItem?.price,
      coupon: undefined,
      payment_type: paymentType,
      transaction_id: paymentId,
      affiliation: ecommerceItem.affiliation,
      items: [ecommerceItem],
    };
  }

  private buildEcommerceItem(policyData: PolicyData, taxAuditQuote: Quote, partner: Partner): any {
    return {
      item_name: 'AC_' + policyData.primaryEntity.type,
      affiliation: partner?.name,
      currency: 'AUD',
      item_brand: 'AuditCover',
      item_category: policyData?.primaryEntity?.type,
      item_category2: policyData?.turnover?.toString(),
      item_category3: policyData?.limitOfCover?.toString(),
      location_id: this.mapGooglePlaceId(policyData.primaryEntity.state ?? ''),
      price: taxAuditQuote?.total,
      quantity: 1,
    };
  }

  private mapGooglePlaceId(state: string): string | undefined {
    if (state === 'NSW') {
      return 'ChIJDUte93TLDWsRLZ_EIhGvgBc';
    }
    if (state === 'VIC') {
      return 'ChIJT5UYfksx1GoRNJWCvuL8Tlo';
    }
    if (state === 'QLD') {
      return 'ChIJ_dxieiTf1GsRmb4SdiLQ8vU';
    }
    if (state === 'WA') {
      return 'ChIJ0YTziS4qOSoRmaMAMt9KDm4';
    }
    if (state === 'ACT') {
      return 'ChIJSxCboN9MFmsRA3huXDhEWOc';
    }
    if (state === 'SA') {
      return 'ChIJ88foW55Yp2oR2ND6PZl5fts';
    }
    if (state === 'TAS') {
      return 'ChIJz_o0fifteqoRZEBAKd2ljyo';
    }
    if (state === 'NT') {
      return 'ChIJDxnz5sJyUSsRdScAAAAAAAA';
    }
    return undefined;
  }

  // GA4's event function is used to track custom events.
  // You can add as many parameters as you want. GA4's tracking model is event-centric.
  private event(name: string, params: any) {
    if (!this.initialized) {
      warn('GA4 is not initialized. Initialize it before tracking events.');
      return;
    }

    ReactGA.event(name, params);
  }

  public trackQuoteCompleted(
    policyData: PolicyData,
    taxAuditQuote: Quote,
    partner: Partner,
    paymentType: any,
    paymentId: string
  ): void {
    this.event(
      'purchase',
      this.buildEcommerce(policyData, taxAuditQuote, partner, paymentType, paymentId)
    );
  }

  public trackQuotePaymentInfo(
    policyData: PolicyData,
    taxAuditQuote: Quote,
    partner: Partner,
    paymentType: any
  ): void {
    this.event(
      'add_payment_info',
      this.buildEcommerce(policyData, taxAuditQuote, partner, paymentType)
    );
  }

  public trackQuoteCheckout(policyData: PolicyData, taxAuditQuote: Quote, partner: Partner): void {
    this.event('begin_checkout', this.buildEcommerce(policyData, taxAuditQuote, partner));
  }

  public trackQuoteReview(policyData: PolicyData, taxAuditQuote: Quote, partner: Partner): void {
    this.event('add_to_cart', this.buildEcommerce(policyData, taxAuditQuote, partner));
  }
}

// Singleton
export const ga4 = new GA4();
