import React from 'react';
import { Chat } from '@aidbase/chat';
import { TaaResponseType } from 'shared/models';

interface ACChatbotProps {
  taaData?: TaaResponseType;
}

export function ACChatbot({ taaData }: ACChatbotProps) {
  return (
    <div className="fixed bottom-4 left-6">
      <Chat
        chatbotID="hzvhdKMqDj_TPDrz2pZDI"
        ticketButtonText="Contact us"
        username={taaData?.contact?.fullName || 'Unknown user'}
        email={taaData?.contact?.email}
        userID={window.location.href}
        isOpen={false}
      />
    </div>
  );
}
