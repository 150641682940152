import React, { useState, useEffect } from 'react';

export interface ACButtonGroupProps {
  options: string[];
  onButtonClick?: (option: string) => void;
  label?: string;
  className?: string;
  defaultValue?: string | null;
}

export const ACButtonGroup = ({
  options = [],
  onButtonClick,
  label,
  className,
  defaultValue,
}: ACButtonGroupProps) => {
  const defaultIndex = defaultValue ? options.indexOf(defaultValue) : null;
  const [selected, setSelected] = useState<number | null>(defaultIndex);

  const handleClick = (index: number) => {
    setSelected(index);

    if (onButtonClick) {
      onButtonClick(options[index]);
    }
  };

  useEffect(() => {
    setSelected(defaultIndex);

    if (options.length === 1) {
      handleClick(0);
    }
  }, [defaultIndex]);

  return (
    <div className={className}>
      {label && <div className="mb-2 font-semibold">{label}</div>}

      {options?.map((option, index) => (
        <button
          key={index}
          onClick={() => handleClick(index)}
          className={`h-fit text-[14px] px-[16px] py-[7px] font-bold ${
            selected === index || options.length === 1
              ? 'bg-[#382EBE] hover:bg-[#382EBE] border-[#382EBE] hover:border-[#382EBE] text-[#fff]'
              : 'bg-[#edf1f7] hover:bg-[#edf1f7] border-[#edf1f7] hover:border-[#edf1f7] text-[#8f9bb3] '
          } ${
            options.length === 1
              ? 'border rounded-md'
              : index === 0
              ? 'border border-l-[#edf1f7] rounded-l-md'
              : index === options.length - 1
              ? 'border border-l-[#c5cee0] hover:border-l-[#c5cee0] rounded-r-md'
              : 'border border-l-[#c5cee0] hover:border-l-[#c5cee0]'
          } normal-case `}
        >
          {option}
        </button>
      ))}
    </div>
  );
};
