import { useQuery } from '@tanstack/react-query';

import { staleTime } from './config/config';
import { useAxios } from 'shared/hooks';

type LOCResponse = {
  limitOfCoverRange: number[];
  limitOfCover: number;
};

export const useGetLimitOfCoverOptions = (applicationId?: string, turnover?: number | null) => {
  const { get } = useAxios();

  return useQuery({
    queryKey: ['limitofcover-options', turnover],
    queryFn: async () => {
      const params = {
        turnover,
      };

      return await get<LOCResponse>(`/api/v2/policyquotes/${applicationId}/limitofcover`, {
        params,
      });
    },
    enabled: !!applicationId && !!turnover,
    staleTime,
  });
};
