import React from 'react';

export interface ACHeaderProps {
  className?: string;
  children: React.ReactNode;
}

export const ACHeader = ({ className, children }: ACHeaderProps) => {
  return <div className={`flex justify-center p-5 ${className} bg-white`}>{children}</div>;
};
