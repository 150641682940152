import { useMutation } from '@tanstack/react-query';
import { useAxios } from 'shared/hooks';

import { TaaResponseType } from 'shared/models';

type EmailQuoteRequest = {
  email: string;
};

export const useEmailQuote = (policyId?: string) => {
  const { post } = useAxios();

  return useMutation<TaaResponseType, Error, EmailQuoteRequest>(
    async (params: EmailQuoteRequest) =>
      await post<EmailQuoteRequest, TaaResponseType>(
        `/api/v2/policyapplications/${policyId}/email`,
        params
      )
  );
};
