import { useMutation } from '@tanstack/react-query';
import { useAxios } from 'shared/hooks';

import { TaaRequestType, TaaResponseType } from 'shared/models';

export const useUpdateTaxAuditApp = (id?: string, isRegenerated = false) => {
  const { patch, put } = useAxios();

  return useMutation<TaaResponseType, Error, TaaRequestType>(async (params: TaaRequestType) =>
    isRegenerated
      ? await put<TaaRequestType, TaaResponseType>(
          `/api/v2/policyapplications/${id}/regenerate`,
          params
        )
      : await patch<TaaRequestType, TaaResponseType>(`/api/v2/policyapplications/${id}`, params)
  );
};
