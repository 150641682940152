import { useCallback } from 'react';

export const useClearStorage = (): ((key: string) => void) => {
  const clearStorageByKey = useCallback((key: string) => {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }, []);

  return clearStorageByKey;
};
