import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { TaaReducer } from './slices/taa.slice';

const rootReducer = combineReducers({
  TaaApplication: TaaReducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
