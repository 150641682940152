import React from 'react';

export interface ACProgressProps {
  value: number;
  max?: number;
  bgColor?: string;
}

export const ACProgress = ({ value, bgColor, max = 100 }: ACProgressProps) => {
  const percentage = (value / max) * 100;
  if (percentage > 0) {
    return (
      <div className="w-full bg-[#d9d9d9] overflow-hidden">
        <div
          className={`h-2.5 ${!bgColor && 'bg-[#06D1DF]'} transition-all duration-300 ease-in-out`}
          style={{ width: `${percentage}%`, backgroundColor: bgColor }}
        ></div>
      </div>
    );
  }

  return null;
};
