import React, { ReactNode } from 'react';
import type { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { DialogActions } from '@mui/material';

import { ACButton } from 'shared/components';

interface ACModalProps {
  content: ReactNode;
  title: string;
  onClose?: () => void;
  open: boolean;
}

export const ACModal: FC<ACModalProps> = ({ content, title, onClose, open }) => {
  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={open}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          pt: 2,
        }}
      >
        <Typography variant="h5" className="text-base font-bold leading-normal text-neutral-800">
          {title}
        </Typography>

        <IconButton color="inherit" onClick={onClose}>
          <SvgIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </SvgIcon>
        </IconButton>
      </Stack>

      <DialogContent className="ta-tooltip-container">{content}</DialogContent>

      <DialogActions>
        <ACButton type="button" size="sm" onClick={onClose}>
          Close
        </ACButton>
      </DialogActions>
    </Dialog>
  );
};
