import { useMutation } from '@tanstack/react-query';
import { useAxios } from 'shared/hooks';

import { TaaRequestType, TaaResponseType } from 'shared/models';

export const useCreateTaxAuditApp = () => {
  const { post } = useAxios();

  return useMutation<TaaResponseType, Error, TaaRequestType>(
    async (params: TaaRequestType) =>
      await post<TaaRequestType, TaaResponseType>('/api/v2/policyapplications', params)
  );
};
