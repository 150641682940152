import React from 'react';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

export const ACLoading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '250px',
      }}
    >
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: theme => (theme.palette.mode === 'light' ? '#909CB4' : '#308fe8'),
          animationDuration: '550ms',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
      />
    </div>
  );
};
