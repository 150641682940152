import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

export interface ACButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  bType?: 'primary' | 'danger' | 'link';
  size?: 'lg' | 'sm';
  isNoXPadding?: boolean;
  xPadding?: string;
  groupBgColor?: string;
}

export const ACButton = ({
  bType = 'primary',
  className,
  size = 'lg',
  isNoXPadding = false,
  children,
  disabled,
  xPadding,
  groupBgColor,
  ...props
}: ACButtonProps) => {
  const commonStyle = `inline-block rounded-full text-[14.4px] font-bold normal-case leading-normal transition duration-150 ease-in-out focus:outline-none focus:ring-0 max-w-[344px] ${
    disabled ? 'cursor-default' : 'cursor-pointer'
  }`;

  const isLargeSize = xPadding ? xPadding : !isNoXPadding && (size === 'lg' ? 'px-20' : 'px-8');

  const typeClasses = {
    primary: cn(
      `
      ${disabled ? 'text-[#8f9bb37a] border-[#8f9bb33d]' : 'text-white border-primary-600'}
      ${isLargeSize} py-3 border-2 hover:bg-primary-600
    `,
      {
        'bg-[#8f9bb33d]': disabled,
        'bg-primary': !disabled && !groupBgColor,
      }
    ),
    danger: `bg-danger ${isLargeSize} text-white py-3 hover:bg-danger-600`,
    link: `no-animation ${
      size === 'lg' ? 'text-lg' : 'text-base'
    } text-[#06d1df] hover:text-[#06d1df]/[.8] ${size === 'lg' ? 'font-normal' : 'font-bold'}`,
  };

  const buttonClasses = cn(commonStyle, typeClasses[bType], className);

  const buttonStyle = {
    backgroundColor: !disabled && groupBgColor ? groupBgColor : undefined,
  };

  return (
    <button className={buttonClasses} style={buttonStyle} disabled={disabled} {...props}>
      {children}
    </button>
  );
};
