import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';

export const ACErrorBoundary = ({
  error,
  isApiError = false,
}: {
  error: string;
  isApiError?: boolean;
}) => {
  const errorMessage = isApiError ? error : 'Oops, it seems like something went wrong.';

  useEffect(() => {
    Sentry.captureException(new Error(errorMessage));
  }, [error]);

  return (
    <div className="bg-[#372DB1] text-white rounded-lg p-4 text-center mt-2">
      <div className="text-lg">{errorMessage}</div>
    </div>
  );
};
