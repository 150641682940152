import React, { FC, useState } from 'react';
import { ACButton, ACDialog, ACInput } from 'shared/components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { RootState } from 'shared/store/store';
import { updateEQDialogStatus, updateSuccessDialogStatus } from 'shared/store/slices/taa.slice';
import { validateEmail } from 'shared/utils';
import { useEmailQuote } from 'shared/hooks/api/useEmailQuote';

interface EmailQuoteDialogProps {
  onSubmit?: () => void;
}

export const EmailQuoteDialog: FC<EmailQuoteDialogProps> = ({ onSubmit }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const { isEQDialogOpen, isSuccessDialogOpen } = useSelector(
    (state: RootState) => state.TaaApplication
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const emailQuoteService = useEmailQuote(id);

  const handleClose = () => {
    dispatch(updateEQDialogStatus(false));
    setError('');
  };

  const handleSentEmail = async () => {
    onSubmit && (await onSubmit());

    await emailQuoteService.mutateAsync(
      { email },
      {
        onSuccess: () => {
          handleClose();
          dispatch(updateSuccessDialogStatus(true));
        },
      }
    );
  };

  return (
    <>
      <ACDialog
        content={
          <ACInput
            placeholder="e.g example@email.com"
            label="Email"
            onChange={e => {
              const input = e.target.value;

              setEmail(input);
              if (validateEmail(input)) {
                setError('');
              } else if (input === '') {
                setError('Email is required');
              } else {
                setError('Email is invalid');
              }
            }}
            errorMessage={error}
            autoComplete="off"
          />
        }
        actionBtn={
          <ACButton
            className="font-medium border-[#382EBE]"
            onClick={handleSentEmail}
            disabled={!email || !!error}
          >
            Send
          </ACButton>
        }
        open={isEQDialogOpen}
        handleClose={() => {
          handleClose();
          setEmail('');
        }}
      />

      {/* Success dialog */}
      <ACDialog
        content={
          <div className="text-center mt-7 text-lg">
            Thanks, your quote has been emailed to <br /> {`${email}`}
          </div>
        }
        title={
          <IconButton
            aria-label="close"
            onClick={() => {
              dispatch(updateSuccessDialogStatus(false));
              setEmail('');
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        }
        open={isSuccessDialogOpen}
        handleClose={() => {
          dispatch(updateSuccessDialogStatus(false));
          setEmail('');
        }}
      />
    </>
  );
};
