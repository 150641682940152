import { ga4 } from 'shared/libs/gtm';

export const useAnalytics = () => {
  return {
    trackQuoteCompleted: ga4.trackQuoteCompleted.bind(ga4),
    trackQuotePaymentInfo: ga4.trackQuotePaymentInfo.bind(ga4),
    trackQuoteCheckout: ga4.trackQuoteCheckout.bind(ga4),
    trackQuoteReview: ga4.trackQuoteReview.bind(ga4),
  };
};
