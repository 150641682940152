import React, { FC } from 'react';

import './indes.scss';
import { ACIcon } from 'shared/components';
import PoweredIcon from 'shared/assets/icon/power-by-ac.svg';

interface ACFooterProps {
  legals?: string;
  privacyUrl?: string;
  contactLink?: string;
  isGroup?: boolean;
}

export const ACFooter: FC<ACFooterProps> = ({ legals, privacyUrl, contactLink, isGroup }) => {
  return (
    <div className="taa-footer">
      {isGroup && (
        <div className="flex items-center justify-center mb-3">
          <ACIcon imgSrc={PoweredIcon} />
        </div>
      )}

      <div className="md:grid md:grid-cols-12">
        <div className="md:col-span-2"></div>
        <div className="md:col-span-8">
          <p>
            {legals
              ? legals
              : `This insurance is issued by Auditcover Australia Pty Ltd (ABN 63 638 872 634, AFSL
            535509) on behalf of the insurer HDI Global Specialty SE (ABN 58 129 395 544, AFSL
            458776).`}
          </p>
          <p>
            <span className="link">
              <a
                href="https://www.auditcover.com/static/policy-wording/"
                target="_blank"
                rel="noreferrer"
                className="text-[#06d1df] hover:text-[#598bff]"
              >
                Policy Wording
              </a>
            </span>
            <span className="link">
              <a
                href={privacyUrl ? privacyUrl : 'https://www.auditcover.com/static/privacy-policy'}
                target="_blank"
                rel="noreferrer"
                className="text-[#06d1df] hover:text-[#598bff]"
              >
                Privacy Policy
              </a>
            </span>
            <span className="link">
              <a
                href={contactLink ? contactLink : 'https://www.auditcover.com/forms/contact-us/'}
                target="_blank"
                rel="noreferrer"
                className="text-[#06d1df] hover:text-[#598bff]"
              >
                Contact Us
              </a>
            </span>
          </p>
          <div className="md:col-span-2"></div>
        </div>
      </div>
    </div>
  );
};
